<template>
  <vs-sidebar
    v-model="isActive"
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="vs-sidebar__wrapper"
    spacer
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ titleType }} RETAILER</h4>
      <feather-icon
        icon="XIcon"
        class="cursor-pointer"
        @click.stop="isActive = false"
      />
    </div>

    <vs-divider class="mb-0" />

    <component
      :is="scrollbarTag"
      :settings="settings"
      class="vs-sidebar__inner"
    >
      <!-- NAME -->
      <div>
        <vs-label text="Name" />
        <vs-input
          v-model="dataName"
          v-validate="'required'"
          class="w-full"
          name="name"
        />
        <v-error :err="errors" field="name" />
      </div>

      <!-- COUNTRY -->
      <div class="mt-4">
        <vs-label text="Country" />
        <v-select
          v-model="dataCountry"
          v-validate="'required'"
          name="country"
          :options="countryList"
          :clearable="false"
        />
        <v-error :err="errors" field="country" />
      </div>

      <!-- ECOMMERCE ADDRESS -->
      <div class="mt-4">
        <vs-label text="E-commerce address" />
        <vs-input v-model="dataUrl" class="w-full" name="url" />
      </div>

      <!-- DESCRIPTION -->
      <div class="mt-4">
        <vs-label text="Description" />
        <vs-textarea v-model="dataDescription" name="description" />
      </div>

      <!-- BUTTONS -->
      <sidebar-buttons
        :valid="isFormValid"
        @click="handleSubmit"
        @cancel="handleClose"
      />
    </component>
  </vs-sidebar>
</template>

<script>
import { mapActions } from 'vuex';
import vSelect from 'vue-select';

import { countryList } from '@/enums/CountryEnum';
import { regionList } from '@/enums/RegionEnum';
import { RetailerAction } from '@/store/actionTypes';

import sidebarMixin from '@/mixins/sidebarMixin';

import vError from '@/components/VError';

export default {
  components: {
    vError,
    vSelect,
  },
  mixins: [sidebarMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataId: null,
      dataName: '',
      dataCountry: null,
      dataUrl: '',
      dataDescription: '',

      regionList,
      countryList,
    };
  },
  computed: {
    isFormValid() {
      return !this.errors.any() && this.dataName && this.dataCountry;
    },
  },
  watch: {
    active(val) {
      if (!val) {
        return;
      }

      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        const { id, name, country, url, description } = this.data;

        this.dataId = id;
        this.dataName = name;
        this.dataCountry = this.getCountryByValue(country);
        this.dataUrl = url;
        this.dataDescription = description;

        this.initValues();
      }
    },
  },
  methods: {
    ...mapActions('retailer', [RetailerAction.add, RetailerAction.update]),

    initValues() {
      if (this.data.id) {
        return;
      }

      this.dataId = null;
      this.dataName = '';
      this.dataCountry = null;
      this.dataUrl = '';
      this.dataDescription = '';
    },
    async handleSubmit() {
      if (!(await this.$validator.validateAll())) {
        return;
      }

      const obj = {
        id: this.dataId,
        name: this.dataName,
        country: this.dataCountry.value,
        country_name: this.dataCountry.label,
        region: this.dataCountry.region,
        url: this.dataUrl,
        description: this.dataDescription,
      };

      this.$vs.loading();

      if (this.shouldUpdate) {
        try {
          await this.updateRetailer(obj);

          this.notifySuccess(
            'Retailer updated',
            'The retailer has been successfully updated.'
          );
        } catch (error) {
          this.notifyError(error, 'Problem with updating retailers.');
        }
      } else {
        delete obj.id;

        try {
          await this.addRetailer(obj);

          this.notifySuccess(
            'Retailer added',
            'The retailer has been successfully added.'
          );
        } catch (error) {
          this.notifyError(error, 'Problem with adding retailers.');
        }
      }

      this.$vs.loading.close();
      this.$emit('close');
      this.initValues();
    },
    getCountryByValue(val) {
      return this.countryList.find(({ value }) => value === val);
    },
    getRegionByValue(val) {
      return this.regionList.find(({ value }) => value === val);
    },
  },
};
</script>
